import React from 'react'
import { connect } from 'react-redux'
import {
  selectScrapTypes,
  selectActiveType
} from './selectors'
import { FormattedMessage } from 'react-intl'
import { setActiveType } from './actions'
import TopTypes from '../../reports/top/TopTypes'

const mapStateToProps = state => {
  return {
    types: selectScrapTypes(state),
    active: selectActiveType(state),
    label: <FormattedMessage id='reports.qm.topTenScrapTypes' />
  }
}

const mapDispatchToProps = {
  onTypeClick: setActiveType
}

export default connect(mapStateToProps, mapDispatchToProps)(TopTypes)
