import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import {
  selectToken,
  selectTimezone,
  selectVisibleLocationIds,
  selectVisibleProjectIds
} from 'reports/qm/selectors'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import Actions, {
  startInit,
  finishInit,
  receiveReports,
  receiveLocations,
  receiveProjects
} from 'reports/qm/actions'
import { SET_NAVIGATION,
  setNavigationDate,
  configureNavigation,
  setNavigationLabelPrefixKey
} from 'containers/Navigation/actions'
import heartbeat from 'lib/saga/heartbeat'
import { TYPE_CWEEK } from 'containers/Navigation/const'
import { selectActiveType, selectReportSubject } from './selectors'
import { receiveTypeReports } from './actions'

function * init () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)

  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone).subtract(1, 'week')))

  yield put(startInit())

  const [locations, projects] = yield all([
    call(api.get, '/locations', { token }),
    call(api.get, '/projects?include_inactive=true', { token })
  ])

  yield put(configureNavigation(TYPE_CWEEK))
  yield put(setNavigationLabelPrefixKey('reports.qm.labelPrefix'))

  yield put(receiveLocations(locations))
  yield put(receiveProjects(projects))

  yield put(finishInit())
}

function * reports () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)
  const timeParams = yield select(selectNavigationRange, timezone)
  const locationIds = (yield select(selectVisibleLocationIds)).join(',')
  const projectIds = (yield select(selectVisibleProjectIds)).join(',')
  const subject = yield select(selectReportSubject)

  const params = {
    ...timeParams,
    locationIds,
    projectIds,
    subject
  }

  const [reports] = yield all([
    call(api.get, '/reports/qm', { params, token })
  ])

  yield put(receiveReports(reports))
}

function * typeReports () {
  const activeType = yield select(selectActiveType)

  if (activeType.id) {
    const token = yield select(selectToken)
    const timezone = yield select(selectTimezone)
    const timeParams = yield select(selectNavigationRange, timezone)
    const locationIds = (yield select(selectVisibleLocationIds)).join(',')
    const projectIds = (yield select(selectVisibleProjectIds)).join(',')
    const subject = yield select(selectReportSubject)

    const params = {
      ...timeParams,
      locationIds,
      projectIds,
      scrapTypeId: activeType.id,
      subject
    }

    const [reports] = yield all([
      call(api.get, '/reports/qm_projects', { params, token })
    ])

    yield put(receiveTypeReports(reports))
  }
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Reports:QM')
  }

  yield call(init)
  yield call(reports)
  yield takeLatest(
    [
      Actions.SHOW_LOCATION,
      Actions.HIDE_LOCATION,
      Actions.SHOW_ALL_LOCATIONS,
      Actions.HIDE_ALL_LOCATIONS,
      Actions.SHOW_PROJECT,
      Actions.HIDE_PROJECT,
      Actions.SHOW_ALL_PROJECTS,
      Actions.HIDE_ALL_PROJECTS,
      Actions.SET_REPORT_SUBJECT,
      SET_NAVIGATION
    ],
    reports
  )
  yield takeLatest([
    Actions.RECEIVE_REPORTS,
    Actions.SET_ACTIVE_TYPE
  ],
  typeReports)
}
