import MultiSelect from 'components/MultiSelect'
import { connect } from 'react-redux'
import { showLocation, hideLocation } from './actions'
import { selectLocationOptions } from './selectors'

const mapStateToProps = (state) => {
  return {
    options: selectLocationOptions(state),
    columnsCount: 2,
    singleColumnWidth: 1290
  }
}

const mapDispatchToProps = {
  onSelect: showLocation,
  onDeselect: hideLocation
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect)
