import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import DropdownButtonSimple from 'components/DropdownButtonSimple'
import { connect } from 'react-redux'
import { setReportSubject } from './actions'
import { FormattedMessage } from 'react-intl'
import { selectReportSubject } from './selectors'

const QMReportSubject = ({ onClick, subject }) => {
  return (
    <DropdownButtonSimple
      color='default'
      style={{ minWidth: 150, justifyContent: 'center', textAlign: 'center', marginRight: '9px' }}
      className='btn--flex'
      content={({ close }) => (
        <Button
          block
          onClick={() => {
            onClick(subject === 'Scrap' ? 'CollectiveScrap' : 'Scrap')
            close()
          }}
        >
          {subject === 'Scrap' && <FormattedMessage id='reports.qm.collectiveScrap' />}
          {subject === 'CollectiveScrap' && <FormattedMessage id='reports.qm.scrap' />}
        </Button>
      )}
    >
      {subject === 'Scrap' && <FormattedMessage id='reports.qm.scrap' />}
      {subject === 'CollectiveScrap' && <FormattedMessage id='reports.qm.collectiveScrap' />}
    </DropdownButtonSimple>
  )
}

QMReportSubject.propTypes = {
  onClick: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    subject: selectReportSubject(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (subject) => {
      dispatch(setReportSubject(subject))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QMReportSubject)
