import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { selectActiveType, selectTypeReports } from './selectors'
import QMProjectTooltip from './QMProjectTooltip'
import TopProjects from '../../reports/top/TopProjects'
import { colorMapper } from './QMReportGraph'

const formatter = _intl => val => val

const mapStateToProps = state => ({
  data: selectTypeReports(state),
  type: selectActiveType(state),
  tooltipContent: QMProjectTooltip,
  formatter,
  colorMapper
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(TopProjects)
