import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { allById, allIds, customById, idsByCustomCollection } from 'lib/reducers'
import Actions from 'reports/qm/actions'
import navigation from 'containers/Navigation/reducer'

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const locations = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      allById: allById(locations),
      allIds: allIds(locations),
      isVisibleById: customById(locations, true)
    }),
    [Actions.SHOW_LOCATION]: (state, { payload: { value: id } }) => ({
      ...state,
      isVisibleById: { ...state.isVisibleById, [id]: true }
    }),
    [Actions.HIDE_LOCATION]: (state, { payload: { value: id } }) => ({
      ...state,
      isVisibleById: { ...state.isVisibleById, [id]: false }
    }),
    [Actions.SHOW_ALL_LOCATIONS]: state => ({
      ...state,
      isVisibleById: state.allIds.reduce((acc, val) => {
        acc[val] = true
        return acc
      }, {})
    }),
    [Actions.HIDE_ALL_LOCATIONS]: state => ({
      ...state,
      isVisibleById: state.allIds.reduce((acc, val) => {
        acc[val] = false
        return acc
      }, {})
    })
  },
  { allIds: [], isVisibleById: {}, allById: {} }
)

const reports = handleActions(
  {
    [Actions.RECEIVE_REPORTS]: (state, { payload }) => ({
      ...state,
      reports: payload
    }),
    [Actions.SET_REPORT_SUBJECT]: (state, { payload: subject }) => ({
      ...state,
      subject
    })
  },
  { reports: [], subject: 'Scrap' }
)

const typeReports = handleActions(
  {
    [Actions.RECEIVE_REPORTS]: (state, { payload }) => ({
      ...state,
      activeType: payload[0] ? { id: payload[0].id, label: payload[0].symbol } : {}
    }),
    [Actions.RECEIVE_TYPE_REPORTS]: (state, { payload: reports }) => ({
      ...state,
      reports
    }),
    [Actions.SET_ACTIVE_TYPE]: (state, { payload: activeType }) => ({
      ...state,
      activeType
    })
  },
  { activeType: {}, reports: [] }
)

const projects = handleActions(
  {
    [Actions.RECEIVE_PROJECTS]: (state, { payload: projects }) => ({
      allById: allById(projects),
      allIds: allIds(projects),
      isVisibleById: customById(projects, true),
      idsByLocation: idsByCustomCollection(projects, 'locationIds')
    }),
    [Actions.SHOW_PROJECT]: (state, { payload: { value: id } }) => ({
      ...state,
      isVisibleById: { ...state.isVisibleById, [id]: true }
    }),
    [Actions.HIDE_PROJECT]: (state, { payload: { value: id } }) => ({
      ...state,
      isVisibleById: { ...state.isVisibleById, [id]: false }
    }),
    [Actions.SHOW_ALL_PROJECTS]: state => ({
      ...state,
      isVisibleById: state.allIds.reduce((acc, val) => {
        acc[val] = true
        return acc
      }, {})
    }),
    [Actions.HIDE_ALL_PROJECTS]: state => ({
      ...state,
      isVisibleById: {}
    })
  },
  { allIds: [], isVisibleById: {}, allById: {} }
)

export default combineReducers({
  init,
  config,
  locations,
  projects,
  reports,
  navigation,
  typeReports
})
