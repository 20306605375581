import React from 'react'
import { PrintContextConsumer } from 'react-to-print'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import ReportContainer from 'components/ReportContainer'
import QMReportHeader from './QMReportHeader'
import QMReportGraph from './QMReportGraph'
import QMScrapTypes from './QMScrapTypes'
import QMProjects from './QMProjects'

const QMReport = ({ isLoaded }) => {
  if (isLoaded) {
    return (
      <ReportContainer selector='#qm-report.report'>
        {({ isPrinting }) => (
          <React.Fragment>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <QMReportHeader handlePrint={handlePrint} />
              )}
            </PrintContextConsumer>
            <QMReportGraph isPrinting={isPrinting} />
            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
              pageBreakInside: 'avoid',
              marginTop: isPrinting ? -50 : 0 }}
            >
              <QMScrapTypes isPrinting={isPrinting} />
              <QMProjects isPrinting={isPrinting} />
            </div>
          </React.Fragment>
        )}
      </ReportContainer>
    )
  } else {
    return <Loader full />
  }
}

QMReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(QMReport)
