import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TopTypes = ({ types, active, onTypeClick, label }) => {
  if (!types || !types.length) return null

  return (
    <div style={{ width: 450 }}>
      <h2>
        {label}
      </h2>
      <ul className='report-list'>
        {types.map(type => (
          <li
            key={type.label}
            onClick={() => onTypeClick(type)}
            className={classnames('report-list__item', {
              'report-list__item--active--planned': active.label === type.label && !type.unplanned,
              'report-list__item--active--unplanned': active.label === type.label && type.unplanned
            })}
          >
            {type.symbol ? `${type.symbol} - ${type.label}` : `${type.label}`}
          </li>
        ))}
      </ul>
    </div>
  )
}

TopTypes.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  ).isRequired,
  active: PropTypes.shape({ label: PropTypes.string }),
  onTypeClick: PropTypes.func.isRequired,
  label: PropTypes.any
}

export default TopTypes
