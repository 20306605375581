import React from 'react'
import PropType from 'prop-types'
import classnames from 'classnames'

const truncate = (str, length) => {
  const dots = str.length > length ? '...' : ''
  return str.substring(0, length) + dots
}

const MultiSelect = ({
  options,
  onSelect,
  onDeselect,
  columnsCount,
  singleColumnWidth
}) => {
  const finalColumnsCount =
    document.documentElement.clientWidth <= singleColumnWidth ? 1 : columnsCount
  const rowsPerColumn = Math.ceil(options.length / (finalColumnsCount * 1.0))

  return (
    <div
      className='multi-select'
      style={{
        width: finalColumnsCount > 1 ? finalColumnsCount * 250 : '100%'
      }}
    >
      <div
        className='multi-select__scrollable'
        style={{ height: rowsPerColumn * 40, width: '100%' }}
      >
        {options.map(option => (
          <div
            key={option.value}
            className={classnames('multi-select__label', {
              'multi-select__label--active': option.selected
            })}
            style={finalColumnsCount === 1 ? { width: '100%' } : {}}
            onClick={() =>
              option.selected ? onDeselect(option) : onSelect(option)
            }
            title={option.label}
          >
            {truncate(option.label, 31)}
          </div>
        ))}
      </div>
    </div>
  )
}

MultiSelect.propTypes = {
  options: PropType.arrayOf(
    PropType.shape({ label: PropType.string, value: PropType.any })
  ),
  onSelect: PropType.func,
  onDeselect: PropType.func,
  columnsCount: PropType.number,
  singleColumnWidth: PropType.number
}

export default MultiSelect
