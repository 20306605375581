import React from 'react'
import PropTypes from 'prop-types'

function truncate (string, length) {
  if (string.length > length) {
    return string.substr(0, length) + '...'
  } else {
    return string
  }
}

const QMReportGraphLabel = ({ x, y, payload: { value } }) => {
  if (!value) {
    return null
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} textAnchor='start' fill='#666' transform='rotate(66)'>
        {truncate(value, 23)}
      </text>
    </g>
  )
}

QMReportGraphLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.string
  })
}

export default QMReportGraphLabel
