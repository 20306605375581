import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  SHOW_LOCATION: 'SHOW_LOCATION',
  HIDE_LOCATION: 'HIDE_LOCATION',
  HIDE_ALL_LOCATIONS: 'HIDE_ALL_LOCATIONS',
  SHOW_PROJECT: 'SHOW_PROJECT',
  HIDE_PROJECT: 'HIDE_PROJECT',
  SHOW_ALL_PROJECTS: 'SHOW_ALL_PROJECTS',
  HIDE_ALL_PROJECTS: 'HIDE_ALL_PROJECTS',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  SHOW_ALL_LOCATIONS: 'SHOW_ALL_LOCATIONS',
  RECEIVE_REPORTS: 'RECEIVE_REPORTS',
  RECEIVE_TYPE_REPORTS: 'RECEIVE_TYPE_REPORTS',
  SET_ACTIVE_TYPE: 'SET_ACTIVE_TYPE',
  SET_REPORT_SUBJECT: 'SET_REPORT_SUBJECT'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveLocations = createAction(Actions.RECEIVE_LOCATIONS)
export const showLocation = createAction(Actions.SHOW_LOCATION)
export const hideLocation = createAction(Actions.HIDE_LOCATION)
export const showAllLocations = createAction(Actions.SHOW_ALL_LOCATIONS)
export const hideAllLocations = createAction(Actions.HIDE_ALL_LOCATIONS)
export const receiveProjects = createAction(Actions.RECEIVE_PROJECTS)
export const showProject = createAction(Actions.SHOW_PROJECT)
export const hideProject = createAction(Actions.HIDE_PROJECT)
export const showAllProjects = createAction(Actions.SHOW_ALL_PROJECTS)
export const hideAllProjects = createAction(Actions.HIDE_ALL_PROJECTS)
export const receiveReports = createAction(Actions.RECEIVE_REPORTS)
export const receiveTypeReports = createAction(Actions.RECEIVE_TYPE_REPORTS)
export const setActiveType = createAction(Actions.SET_ACTIVE_TYPE)
export const setReportSubject = createAction(Actions.SET_REPORT_SUBJECT)
