import React from 'react'
import MultiSelect from 'components/MultiSelect'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import DropdownButton from 'components/DropdownButton'
import {
  showAllProjects,
  hideAllProjects,
  showProject,
  hideProject
} from './actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectProjectOptions,
  selectVisibleProjectIds,
  selectProjectIds
} from './selectors'

const mapStateToSelectProps = state => {
  return {
    options: selectProjectOptions(state),
    columnsCount: 2,
    singleColumnWidth: 1120
  }
}

const mapDispatchToSelectProps = {
  onSelect: showProject,
  onDeselect: hideProject
}

const Select = connect(
  mapStateToSelectProps,
  mapDispatchToSelectProps
)(MultiSelect)

const QMProjectsDropdown = ({
  showAllProjects,
  hideAllProjects,
  projectsCount
}) => {
  return (
    <div className='report-header__navigation-item'>
      <DropdownButton
        color='default'
        className='btn--flex'
        popperConfig={{ placement: 'bottom-end' }}
        content={() => (
          <div className='multi-select-box multi-select-box multi-select-box--realization-menu'>
            <div className='multi-select-box__actions'>
              <Button size='small' color='secondary' onClick={showAllProjects}>
                <FormattedMessage id='reports.scrapTrend.selectAll' />
              </Button>
              <Button size='small' color='secondary' onClick={hideAllProjects}>
                <FormattedMessage id='reports.scrapTrend.selectNone' />
              </Button>
            </div>

            <div className='multi-select-box__select'>
              <Select />
            </div>
          </div>
        )}
      >
        {projectsCount < 0 && (
          <FormattedMessage id='reports.scrapTrend.allProjects' />
        )}
        {projectsCount === 0 && (
          <FormattedMessage id='reports.scrapTrend.noneProject' />
        )}
        {projectsCount > 0 && (
          <FormattedMessage
            id='reports.scrapTrend.nProjects'
            values={{ n: projectsCount }}
          />
        )}
      </DropdownButton>
    </div>
  )
}

QMProjectsDropdown.propTypes = {
  showAllProjects: PropTypes.func,
  hideAllProjects: PropTypes.func,
  projectsCount: PropTypes.number
}

const mapStateToProps = state => {
  const visibleProjectsCount = selectVisibleProjectIds(state).length
  const projectsCount = selectProjectIds(state).length

  return {
    projectsCount:
      projectsCount === visibleProjectsCount ? -1 : visibleProjectsCount
  }
}

const mapDispatchToProps = {
  showAllProjects,
  hideAllProjects
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QMProjectsDropdown)
