import React from 'react'
import PropTypes from 'prop-types'
import Unit from 'components/Unit'

const QMProjectTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const data = payload[0]
    const { label, value } = data.payload
    return (
      <div className='recharts-custom-tooltip'>
        <div className='recharts-custom-tooltip__label'>
          {label}
        </div>

        <div className='recharts-custom-tooltip__value'>
          <Unit value={value} unit='piece' />
        </div>
      </div>
    )
  }

  return null
}

QMProjectTooltip.propTypes = {
  active: PropTypes.boolean,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ),
  label: PropTypes.string.isRequired
}

export default QMProjectTooltip
