import React from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Tooltip,
  LabelList
} from 'recharts'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { connect } from 'react-redux'
import Unit from 'components/Unit'
import { selectAllReports } from './selectors'
import QMReportGraphLabel from './QMGraphLabel'

const percentageFormatter = val => `${(val * 100).toFixed(2)} %`

export const colorMapper = entry => {
  let color
  const val = entry.value || entry.quantity

  if (val <= 49) {
    color = 'green'
  } else if (val >= 50 && val <= 99) {
    color = 'orange'
  } else {
    color = 'red'
  }

  return color
}

const QMTooltipValue = ({ name, value }) => (
  <div style={{ display: 'flex' }}>
    <div className='recharts-custom-tooltip__label'>
      {name}
      &nbsp;
    </div>
    <div className='recharts-custom-tooltip__value'>
      {value}
    </div>
  </div>
)

QMTooltipValue.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
}

const QMTooltip = ({ payload }) => {
  if (!payload || !payload[0]) {
    return null
  }

  const { symbol, totalQuantity, percentage, quantity } = payload[0].payload

  return (
    <div className='recharts-custom-tooltip'>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>
          {symbol}
        </h4>
      </div>
      <div className='report-graph-tooltip__footer'>
        <FormattedMessage id='reports.qm.percentage'>
          {text => (
            <QMTooltipValue name={text} value={percentageFormatter(percentage)} />
          )}
        </FormattedMessage>
        <FormattedMessage id='reports.qm.quantity'>
          {text => (
            <QMTooltipValue name={text} value={<Unit value={quantity} unit='piece' />} />
          )}
        </FormattedMessage>
        <FormattedMessage id='reports.qm.total'>
          {text => (
            <QMTooltipValue name={text} value={<Unit value={totalQuantity} unit='piece' />} />
          )}
        </FormattedMessage>
      </div>
    </div>
  )
}

QMTooltip.propTypes = {
  payload: PropTypes.array
}

class QMReportGraph extends React.Component {
  constructor () {
    super()
    this.state = { animate: true }
  }

  getSnapshotBeforeUpdate (prevProps) {
    const shouldAnimate = !isEqual(prevProps.data, this.props.data)
    return shouldAnimate
  }

  componentDidUpdate (_prevProps, prevState, shouldAnimate) {
    if (prevState.animate !== shouldAnimate) {
      this.setState({ animate: shouldAnimate }) // eslint-disable-line react/no-did-update-set-state
    }
  }

  render () {
    const { data, isPrinting } = this.props

    let maxDomain = Math.min(Math.max(...data.map(d => Math.ceil(+d.percentage / 0.1) * 0.1)), 1)
    if (maxDomain < 0) maxDomain = 1

    return (
      <div
        className='report-recharts-container'
        style={{ width: '100%', height: 500, marginTop: 18, marginBottom: 18 }}
      >
        <ResponsiveContainer>
          <ComposedChart
            width={600}
            height={400}
            data={data}
            margin={{ top: 20, right: 20, bottom: 120, left: 20 }}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey='symbol'
              tick={<QMReportGraphLabel />}
              interval={0}
            />
            <YAxis
              orientation='right'
              dataKey='percentage'
              domain={[0, maxDomain]}
              tickFormatter={percentageFormatter}
              // tickCount={11}
            />
            <Bar
              dataKey='percentage'
              barSize={40}
              fill='#2F81B7'
              isAnimationActive={this.state.animate}
            >
              <LabelList position='top' content={({ index, x, y, ...rest }) => {
                if (!isPrinting) return null

                const { quantity, totalQuantity, percentage } = data[index]

                return (
                  <text x={x + 20} y={y - 22} className='recharts-text recharts-label'>
                    <tspan x={x + 20} textAnchor='middle'>{percentageFormatter(percentage)}</tspan>
                    <tspan x={x + 20} dy='20' textAnchor='middle'>{`${quantity} / ${totalQuantity}`}</tspan>
                  </text>
                )
              }} />
              {data.map(entry => {
                return (
                  <Cell key={entry.symbol} fill={colorMapper(entry)} />
                )
              })}
            </Bar>
            <Tooltip content={QMTooltip} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  data: selectAllReports(state)
})

QMReportGraph.propTypes = {
  data: PropTypes.array,
  isPrinting: PropTypes.bool
}

export default connect(mapStateToProps)(QMReportGraph)
