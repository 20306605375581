import React from 'react'
import ReportHeader from 'components/ReportHeader'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import DropdownButton from 'components/DropdownButton'
import Button from 'components/Button'
import QMLocations from './QMLocations'
import { selectVisibleLocationIds, selectLocationIds } from './selectors'
import { connect } from 'react-redux'
import { showAllLocations, hideAllLocations } from './actions'
import QMProjectsDropdown from './QMProjectsDropdown'
import QMReportSubject from './QMReportSubject'

const Header = ({
  locationsCount,
  selectAllLocations,
  selectNoneLocations,
  handlePrint
}) => {
  return (
    <FormattedMessage id='reports.qm.name'>
      {text => (
        <ReportHeader name={text} handlePrint={handlePrint}>
          <QMReportSubject />
          <div className='report-header__navigation-item'>
            <DropdownButton
              color='default'
              className='btn--flex'
              popperConfig={{ placement: 'bottom-end' }}
              content={() => (
                <div className='multi-select-box multi-select-box multi-select-box--realization-menu'>
                  <div className='multi-select-box__actions'>
                    <Button
                      size='small'
                      color='secondary'
                      onClick={selectAllLocations}
                    >
                      <FormattedMessage id='reports.scrapTrend.selectAll' />
                    </Button>
                    <Button
                      size='small'
                      color='secondary'
                      onClick={selectNoneLocations}
                    >
                      <FormattedMessage id='reports.scrapTrend.selectNone' />
                    </Button>
                  </div>

                  <div className='multi-select-box__select'>
                    <QMLocations />
                  </div>
                </div>
              )}
            >
              {locationsCount < 0 && (
                <FormattedMessage id='reports.scrapTrend.allLocations' />
              )}
              {locationsCount === 0 && (
                <FormattedMessage id='reports.scrapTrend.noneLocation' />
              )}
              {locationsCount > 0 && (
                <FormattedMessage
                  id='reports.scrapTrend.nLocations'
                  values={{ n: locationsCount }}
                />
              )}
            </DropdownButton>
          </div>
          <QMProjectsDropdown />
        </ReportHeader>
      )}
    </FormattedMessage>
  )
}

Header.propTypes = {
  locationsCount: PropTypes.number,
  selectAllLocations: PropTypes.func,
  selectNoneLocations: PropTypes.func,
  handlePrint: PropTypes.func
}

const mapStateToProps = state => {
  const locationsCount = selectLocationIds(state).length
  const visibleLocationsCount = selectVisibleLocationIds(state).length

  return {
    locationsCount:
      locationsCount === visibleLocationsCount ? -1 : visibleLocationsCount
  }
}

const mapDispatchToProps = {
  selectAllLocations: showAllLocations,
  selectNoneLocations: hideAllLocations
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
