import React from 'react'
import PropTypes from 'prop-types'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell
} from 'recharts'
import { intlShape } from 'react-intl'

const BAR_SIZE = 60

const TopProjects = ({
  data,
  type: { symbol, label },
  intl,
  tooltipContent,
  formatter,
  colorMapper
}) => {
  const fmt = formatter(intl)

  if (!symbol && !label) return null

  return (
    <div style={{ width: 900 }}>
      <h2>{symbol ? `${symbol} - ${label}` : `${label}`}</h2>
      <div style={{ width: '100%', height: 700, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            width={900}
            height={700}
            data={data}
            margin={{
              bottom: 300,
              left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey='label'
              angle={75}
              textAnchor='start'
              dx={5}
              dy={5}
              interval={0}
            />
            <YAxis tickFormatter={fmt} />
            <Tooltip content={tooltipContent} />
            <Bar dataKey='value' fill='#FFB1A1' barSize={BAR_SIZE}>
              {data.map(entry => {
                let color
                if (colorMapper) {
                  color = colorMapper(entry)
                } else {
                  color = '#FFB1A1'
                }

                return <Cell key={entry.label} fill={color} />
              })}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

TopProjects.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.shape({
    label: PropTypes.string,
    symbol: PropTypes.string
  }).isRequired,
  intl: intlShape,
  tooltipContent: PropTypes.any,
  formatter: PropTypes.func,
  colorMapper: PropTypes.func
}

export default TopProjects
